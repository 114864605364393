<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-27 20:28:20
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-03 16:04:08
-->
<template>
<HqDrawer ref="drawer"  title="审核历史记录"  :before-close="cancel"   :visible.sync="showVisisble" size="90%">

    <div class="ty-drawer-content">
        <div v-loading="loading" class="ty-drawer-list"
             element-loading-text="正在读取数据">
            <el-table 
                    :border="!skin"
                    :stripe="true"
                    style="width: 100%;margin:12px 0 10px;"
                    :style="{'margin-top': skin?'12px':'30px'}"
                    highlight-current-row
                    :data="tableData">
    			<el-table-column :show-overflow-tooltip="true" :formatter="formatDate" property="signTime" label="签订时间" min-width="140"></el-table-column>
    			<el-table-column :show-overflow-tooltip="true" :formatter="formatFormAllData" property="status" label="审核结果" min-width="140"></el-table-column>
    			<el-table-column :show-overflow-tooltip="true" property="checkRemark" label="驳回原因" min-width="140"> 
    				<template slot-scope="scope">
    					{{scope.row.status == 3 ? '' : scope.row.checkRemark}}
    				</template>
    			</el-table-column>
    			<el-table-column :show-overflow-tooltip="true" property="checkUserName" label="审核人" min-width="140"></el-table-column>
    			<el-table-column :show-overflow-tooltip="true" :formatter="formatDate" property="checkTime" label="审核时间" min-width="140"></el-table-column>
    		</el-table>
    		
            <el-row type="flex" class="row-bg ty-page-fix" justify="center">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
                </el-pagination>
            </el-row>
    
        </div>
    </div>

    <!-- 侧滑关闭必须有 -->
    <div slot="footer">
    <!-- <div slot="footer" v-if="openType==3 && skin"> -->
        <el-button @click="cancel()">返回</el-button>
    </div>
</HqDrawer>
</template>

<script>
export default {
    name: 'history-audit-recard-modal',
    data(){
        return {
            // skin: '', // 皮肤
            // openType: '', // 判断侧滑还是弹窗
            // contractRecordId:'',
            showVisisble: this.visible,
            currentPage: 1,
            pageInfo: {
                pageSize: 10,
                total: 0
            },
            tableData: null,
            keyword: '',
            orderBy: '',
            loading: true,
            checkStatus:[
                {"id":"","text":"全部"},
                {"id":0,"text":"待签订"},
                {"id":2,"text":"待审核"},
                {"id":3,"text":"审核通过"},
                {"id":4,"text":"驳回"},
                {"id":5,"text":"异常"},
            ],
        }
    },
    props: {
        skin: String,
        openType: String,
        contractRecordId: Number,
        visible: Boolean,
        host: String,
    },
    watch: {
        visible( newValue ){
            this.showVisisble = newValue;
            if( newValue ){
                this.bindData(1);
            }
        }
    },
    mounted(){
        // let skin = getUrlParam("skin");
        // this.skin = skin=='green.css';
        // this.openType = getUrlParam("openType"); // openType的数值为3是侧滑打开
        // this.contractRecordId = getUrlParam("contractRecordId");
        
    },
    methods: {
      init() {
        this.$refs.drawer.init()
      },
        //获取列表数据
        bindData(curPage) {
            this.currentPage = curPage;
            this.loading = true;
            this.$fetch('findCheckList', {
                contractRecordId: this.contractRecordId,
                pageSize:this.pageInfo.pageSize,
                curPage: curPage
            }).then(response => {
                    response = response || { data: [] }
                    const result = response.data;
                    this.tableData = result;
                    this.pageInfo.total = response.pageInfo.total;
                })
                .catch(error => {
                    console.log(error);
                    this.$message.error(`请求失败:${error.message}`);
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        
        //修改每页记录大小
        handleSizeChange(pageSize) {
            this.pageInfo.pageSize = pageSize;
            this.bindData(1);
        },
        //换页
        handleCurrentChange(curPage) {
            this.bindData(curPage);
        },
        //排序
        handleSortChange(sort) {
            this.orderBy = sort.prop;
            if(sort.order=='descending') {
                this.orderBy += ' desc';
            }
            this.bindData(1);
        },
        
        //时间格式化
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            }
            let dt = new Date(data);
            let dtYear = dt.getFullYear()
            let dtMonth = dt.getMonth()+1<10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1;
            let dtDate = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
            let dtHours = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
            let dtMin = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
            return dtYear + '-' + dtMonth + '-' + dtDate + ' ' + dtHours + ':' + dtMin;
        },
        //格式化数据
        formatFormAllData (row, column){
            let list =  this.checkStatus;
            for (let i = 0; i < list.length; i++) {
                if(list[i].id===row[column.property]&&!this.isEmpty(row[column.property])){
                    return list[i]["text"];
                }
            }
            return row[column.property];
        },
        //字符串判空
        isEmpty(obj) {
            if (typeof obj === 'undefined' || obj == null || obj === '') {
                return true;
            } else {
                return false;
            }
        },
        cancel(){
          this.$refs.drawer.close()
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style scoped lang="scss">
  .ty-drawer-content {
    width: 94%;
    margin: 0 auto;
  }
</style>